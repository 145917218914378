import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from 'gatsby-source-storyblok'

import { getLangFromContext, filterCurrentLocale, resolveLink } from '../utils'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ProductHeader from '../components/sections/ProductHeader'
import ProductIntroduction from '../components/sections/ProductIntroduction'
import CallToAction from '../components/sections/CallToAction'

const ProductPage = ({ pageContext, location, data }) => {
  const lang = getLangFromContext(pageContext)
  let story = pageContext?.story
  story = useStoryblokState(story)
  const blok = story.content
  const product = {
    ...blok,
    uuid: story.uuid,
  }

  const components =
    story.content.body?.map((blok) => {
      if (blok?.component === 'ProductData' && !blok.product) {
        blok = {
          ...blok,
          product: {
            _uid: product._uid,
            uuid: story.uuid,
            title: product.title,
            productFamily: product.productFamily,
          },
        }
      }

      return <StoryblokComponent blok={blok} key={blok._uid} />
    }) || []

  const allProductFacts = filterCurrentLocale(data.allProductFacts?.nodes, lang)

  const productFacts = allProductFacts
    .filter((fact) => {
      return (
        blok.facts.includes(fact.defaultValue) ||
        blok.facts.includes(fact.value)
      )
    })
    .map((fact) => ({
      name: fact.name,
      text: fact.value,
    }))

  const productHeaderBlok = {
    _uid: blok._uid,
    title: blok.title,
    image: blok.image,
    facts: productFacts,
    contactBackUrl: location.pathname,
    product,
  }

  const productIntroductionBlok = {
    description: blok.description,
    highlights: blok.highlights,
    biobasedCarbonShare: blok.biobasedCarbonShare,
    contactBackUrl: location.pathname,
    product,
  }

  const productsCallToActionBlok =
    pageContext?.siteConfig?.productsCallToAction?.[0]
  const productsPageUrl =
    productsCallToActionBlok?.link?.url ||
    productsCallToActionBlok?.link?.cached_url ||
    ''
  const callToActionBlok = {
    ...productsCallToActionBlok,
    link: undefined,
  }
  const navigateProductsPageProps = location.state?.referringFromProductsPage
    ? -1
    : resolveLink(productsPageUrl)

  return (
    <Layout context={pageContext} location={location} product={product}>
      <main {...storyblokEditable(story.content)}>
        <ProductHeader blok={productHeaderBlok} />
        <ProductIntroduction blok={productIntroductionBlok} className="!mt-0" />
        {components}
        <CallToAction
          as="button"
          blok={callToActionBlok}
          onClick={() => navigate(navigateProductsPageProps)}
        />
      </main>
    </Layout>
  )
}

export default ProductPage

export const Head = ({ pageContext, location }) => {
  const storyContent = pageContext?.story?.content || {}

  const seo = {
    ...storyContent.seo,
    title:
      storyContent.seo?.title ||
      `${storyContent.productFamily} ${storyContent.title}`,
    description: storyContent.seo?.description || storyContent.description,
    image: storyContent.seo?.image || storyContent.image?.filename,
  }

  return (
    <Seo
      location={location}
      seo={seo}
      noindex={storyContent.noindex}
      ignorePageTitleTemplate={storyContent.ignorePageTitleTemplate}
      siteConfig={pageContext?.siteConfig}
    />
  )
}

export const query = graphql`
  query ($langCode: String!) {
    locales: allLocale(filter: { language: { eq: $langCode } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allProductFacts: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "product-facts" } }
    ) {
      nodes {
        ...DatasourceEntry
      }
    }
  }
`
